import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static classes = [ "activeTab", "activePanel" ]
  static targets = ['tab', 'panel']
  static values = {
    index: 0,
    updateAnchor: Boolean,
    scrollToAnchor: Boolean,
    scrollActiveTabIntoView: Boolean
  }

  connect() {
    this.showPanel(null)
  }

  // Changes to the clicked tab
  select(event) {
    // find tab matching (with same id as) the clicked btn
    let selectedPanel = this.element.querySelector('#' + event.currentTarget.getAttribute('aria-controls'))

    this.showPanel(selectedPanel)
    // hide everything
    // this.panelTargets.map(x => x.hidden = true) // hide all tabs
    // this.btnTargets.map(x => x.classList.remove(...this.activeClasses)) // deactive all btns

    // then show selected
    // selectedPanel.hidden = false // show current tab
    // event.currentTarget.classList.add(...this.activeClasses) // activate current button
  }

  showPanel(selectedPanel) {
    this.panelTargets.forEach((panel, index) => {
      const tab = this.tabTargets.find((tab) => tab.getAttribute('aria-controls') === panel.id)

      if (panel === selectedPanel && panel.classList.contains('hidden')) {
        panel.classList.remove('hidden')
        tab.ariaSelected = 'true'
        tab.dataset.active =  true
        if (this.hasInactiveTabClass) tab?.classList?.remove(...this.inactiveTabClasses)
        if (this.hasActiveTabClass) tab?.classList?.add(...this.activeTabClasses)
      } else {
        panel.classList.add('hidden')
        tab.ariaSelected = null
        delete tab.dataset.active
        if (this.hasActiveTabClass) tab?.classList?.remove(...this.activeTabClasses)
        if (this.hasInactiveTabClass) tab?.classList?.add(...this.inactiveTabClasses)
      }
    })

    if (this.hasSelectTarget) {
      this.selectTarget.selectedIndex = this.indexValue
    }

    if (this.scrollActiveTabIntoViewValue) this.scrollToActiveTab()
  }
}
